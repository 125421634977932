<template>
  <div
    class="gift-details"
    :class="{ 'gift-details--christmas': isChristmas }"
  >
    <div
      v-if="isChristmas"
      class="gift-details__gun"
    />
    <div class="gift-details__content-wrapper">
      <h3 class="gift-details__title">
        {{ $t('giftVoicemod.formTitle') }}
      </h3>

      <form
        class="gift-details__form"
        novalidate
        @submit.prevent="handleSubmit"
      >
        <base-input
          name="recipientName"
          :class="['gift-details__form-input', { 'gift-details__form-input--error': isInvalidName }]"
          :placeholder="$t('giftVoicemod.formInputNamePlaceholder')"
          :value="recipientName"
          @input="handleNameInput"
        >
          <template #label>
            {{ $t('giftVoicemod.formInputNameLabel') }}
          </template>
        </base-input>
        <div
          v-if="isInvalidName"
          class="gift-details__form-input--error-message"
        >
          {{ $t('giftVoicemod.formInputNameError') }}
        </div>

        <base-input
          name="recipientEmail"
          :class="['gift-details__form-input', { 'gift-details__form-input--error': isInvalidEmail }]"
          type="email"
          :placeholder="$t('giftVoicemod.formInputEmailPlaceholder')"
          :value="recipientEmail"
          @input="handleEmailInput"
        >
          <template #label>
            {{ $t('giftVoicemod.formInputEmailLabel') }}
          </template>
        </base-input>
        <div
          v-if="isInvalidEmail"
          class="gift-details__form-input--error-message"
        >
          {{ $t('giftVoicemod.formInputEmailError') }}
        </div>

        <div class="gift-details__form-input gift-details__selected-plan">
          <div>{{ $t('giftVoicemod.selectedPlan') }}</div>
          <div class="gift-details__plan-details">
            <span>{{ selectedPlan.planTranslatedName }}</span>
            <span>{{ planPrice }}</span>
          </div>
        </div>

        <base-button
          :is-type="['primary']"
          :is-disabled="isButtonDisabled"
          class="gift-details__form-submit"
          type="submit"
        >
          <template #label>
            {{ $t('giftVoicemod.formSubmitButton') }}
          </template>
        </base-button>
      </form>
    </div>

    <div class="gift-details__terms">
      <a
        :href="giftLicensePrivacyPolicy"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ $t('giftVoicemod.termsAndConditions') }}
      </a>
    </div>
    <img
      v-if="isChristmas"
      src="@/assets/images/gift-christmas-pray.png"
      class="gift-details__pray"
    >
  </div>
</template>

<script>
import BaseButton from '@RepoComponent/BaseButton/BaseButton';
import BaseInput from '@RepoComponent/BaseInput/BaseInput';
import { URL_CONSTANTS } from '@/externalURLs';
import { ROUTER_PATHS } from '@/router/paths';
import { metricsService } from  '@/metrics/metrics';
import { mParticleMetric } from '../../metrics/mparticle';

export default {
    name: 'GiftVoicemodFormComponent',
    components: {
        BaseInput,
        BaseButton,
    },
    props: {
        selectedPlan: {
            type: Object,
            default: null,
        },
        isChristmas: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            giftLicensePrivacyPolicy: URL_CONSTANTS.GIFT_LICENSE_PRIVACY_POLICY,
            recipientName: '',
            recipientEmail: '',
            isInvalidEmail: false,
            isInvalidName: false,
        };
    },
    computed: {
        isButtonDisabled() {
            return this.recipientEmail.length === 0 || this.recipientName.length === 0;
        },
        planPrice() {
            return this.selectedPlan?.fullPrice || this.selectedPlan?.price;
        },
    },
    methods: {
        handleEmailInput(email) {
            this.recipientEmail = email;
            if (this.isValidEmail()) {
                this.isInvalidEmail = false;
            }
        },
        handleNameInput(name) {
            this.recipientName = name;
            if (this.isValidName()) {
                this.isInvalidName = false;
            }
        },
        isValidName() {
            return this.recipientName.length >= 2;
        },
        isValidEmail() {
            const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            return emailRegex.exec(this.recipientEmail);
        },
        trackSubmit() {
            const eventData = {
                step: 'submit',
                product_name: this.selectedPlan.internalEvent,
                product_price: this.selectedPlan.fullPrice || this.selectedPlan.price,
                voicemod_system: 'account.voicemod.net',
                is_beg: false,
            };
            metricsService.trackEvent(eventData);
            mParticleMetric.track(
                mParticleMetric.EVENTS.GIFT_LICENSE, eventData, mParticleMetric.EVENT_TYPE.Transaction,
            );
        },
        handleSubmit() {
            if (!this.isValidName()) {
                this.isInvalidName = true;
            }
            if (!this.isValidEmail()) {
                this.isInvalidEmail = true;
            }
            if (!this.isInvalidName && !this.isInvalidEmail) {
                this.trackSubmit();
                const checkoutRoute = this.$router.resolve({
                    path: ROUTER_PATHS.CHECKOUT,
                    query: {
                        productId: this.selectedPlan.productId,
                        giftingEmail: this.recipientEmail,
                        giftingName: this.recipientName,
                        payload: btoa(`${this.recipientEmail}|${this.recipientName}|${this.selectedPlan.productId}`),
                    },
                });
                window.open(checkoutRoute.href, '_blank');
            }
        },
    },
};
</script>

<style lang="scss" src="./GiftVoicemodFormComponent.scss" />
