<template>
  <div class="pricing-plan__wrapper">
    <div
      v-if="!isSelected && plan.bestValue"
      class="pricing-plan__featured"
    >
      {{ $t('giftVoicemod.bestValue') }}
    </div>
    <button
      :class="[{
        'pricing-plan--selected': isSelected,
        'pricing-plan--christmas': isChristmas,
      }, 'pricing-plan']"
      @click="handleSelectPlan"
    >
      <h3 class="pricing-plan__name">
        {{ plan.planTranslatedName }}
      </h3>
      <div
        v-if="plan.originalPrice"
        class="pricing-plan__tag"
      >
        <span class="pricing-plan__label">
          {{ $t('giftVoicemod.offerLabel') }}
        </span>
        <span class="pricing-plan__offer">{{ plan.originalPrice }}</span>
      </div>

      <div class="pricing-plan__price">
        {{ planPrice }}
      </div>
      <span
        v-if="isSelected"
        data-testid="check-circle"
        class="pricing-plan--selected-icon"
      />
    </button>
  </div>
</template>

<script>

export default {
    props: {
        plan: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        isChristmas: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        planPrice() {
            return this.plan?.fullPrice || this.plan?.price;
        },
    },
    methods: {
        handleSelectPlan() {
            this.$emit('onSelectPlan', this.plan);
        },
    },
};
</script>

<style scoped lang="scss" src="./PricingPlanComponent.scss" />