<template>
    <div
        :class="[
            isDisabled ? 'base-input--is-disabled' : null,
            layout ? `base-input--is-${layout}` : null,
            'base-input'
        ]"
    >
        <slot name="icon"></slot>
        <label :for="id">
            <span
                v-if="$slots['label']"
                class="base-input__label">
                <slot name="label"></slot>
            </span>
            <span class="base-input__field">
                <base-icon
                    v-if="isSearch"
                    name="search"
                    size="normal"
                ></base-icon>
                <input
                    :id="id"
                    :ref="id"
                    :type="type"
                    :value="value"
                    :disabled="isDisabled"
                    :placeholder="placeholder"
                    :maxlength="maxlength"
                    :spellcheck="spellcheck"
                    :autocomplete="autocomplete ? 'on' : 'off'"
                    :readonly="isReadOnly"
                    @input="updateValue"
                    @focus="focus"
                    @blur="blur"
                    @paste="paste"
                >
                    <base-icon
                        v-if="(isSearch || isSearchNoIcon) && value.length"
                        name="close"
                        size="small"
                        @click.native="empty"
                    ></base-icon>
            </span>
        </label>
    </div>
</template>

<script>
    export default {
        name: 'baseInput',
        components: {
            BaseIcon: () => import (/* webpackChunkName: "BaseIcon"*/ '../BaseIcon/BaseIcon')
        },
        props: {
            type: {
                type: String,
                default: 'text'
            },

            id: {
                type: String,
                default: 'test'
            },
            // eslint-disable-next-line
            value: '', // to avoid type checking prop issues as the type of the input could change (number, text, etc)

            isDisabled: {
                type: Boolean,
                default: false
            },

            hasError: {
                type: Boolean,
                default: false
            },

            layout: {
                type: String,
                default: '',
            },

            placeholder: {
                type: String,
                default: '',
            },

            maxlength: {
                type: Number,
                required: false
            },

            spellcheck: {
                type: Boolean,
                default: false
            },

            autocomplete: {
                type: Boolean,
                default: true
            },

            isReadOnly: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            isSearch() {
                return this.layout === 'search';
            },

            isSearchNoIcon() {
                return this.layout === 'search-no-icon';
            }
        },
        methods: {
            updateValue(e) {
                this.$emit('input',e.target.value);
            },

            empty() {
                this.$emit('empty');
            },

            focus() {
                this.$emit('focus');
            },

            blur(e) {
                this.$emit('blur', e.target.value);
            },

            paste(e) {
                this.$emit('paste', e.clipboardData);
            }
        },
        mounted() {
            this.$emit('onInputLoad', this.$refs[this.id]);
        }
    }
</script>

<style lang="scss" scoped src="./BaseInput.scss"></style>
