import { render, staticRenderFns } from "./PricingPlanComponent.vue?vue&type=template&id=45b58a20&scoped=true"
import script from "./PricingPlanComponent.vue?vue&type=script&lang=js"
export * from "./PricingPlanComponent.vue?vue&type=script&lang=js"
import style0 from "./PricingPlanComponent.scss?vue&type=style&index=0&id=45b58a20&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45b58a20",
  null
  
)

export default component.exports